import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CardContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border: 1px solid #dedfe4;
  border-radius: 8px;

  @media ${device.tablet} {
    max-width: 336px;
  }

  @media ${device.desktopLG} {
    max-width: 456px;
  }

  @media ${device.desktopXL} {
    max-width: 550px;
  }

  @media ${device.desktopXXL} {
    max-width: 530px;
  }

  @media ${device.desktopXXXL} {
    max-width: 650px;
  }
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedfe4;
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;

  .gatsby-image-wrapper {
    max-width: 55px !important;
    max-height: 55px !important;

    @media ${device.desktopLG} {
      max-width: 65px !important;
      max-height: 65px !important;
    }
  }

  img {
    max-width: 55px;
    max-height: 55px;
  }
`
