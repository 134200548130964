import React from 'react'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'

// Styles
import { CardContainer, CardHeader } from './style'
import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'
import { contentData } from '../../assets/data/_comparativoData'

type ContentDataProps = {
  title: string;
  subtitle: string;
  tax: string;
  image: string;
  discount: {
    description: string;
  };
  requests: {
    description: string;
  };
}

const Comparativo = () => {
  const data = usePageQuery()

  return (
    <section className='bg-grayscale--100'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 align-items-center'>
            <h3 className='text-center fs-24 lh-30 fs-md-32 lh-md-40 text-grayscale--500'>Comparativo entre taxas</h3>
            <p className='text-center fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400'>Taxas referentes ao Financiamento Imobiliário</p>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2 }}
              lg={{ items: 2 }}
              xl={{ items: 2 }}
            >
              {contentData.map((item: ContentDataProps, index: number) => (
                <CardContainer key={index}>
                  <CardHeader>
                    <div>
                      <p className='mb-0 mb-3 fs-20 lh-25 fs-lg-24 lh-lg-30 fw-600 text-grayscale--500 mb-0'>{item.title}</p>
                      <p className='mb-0 fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400'>{item.subtitle}<strong>{item.tax}</strong></p>
                    </div>
                    <Img fluid={data[item.image].fluid} alt={item.tax} />
                  </CardHeader>
                  <div className='d-flex align-items-start mb-4'>
                    <div>
                      <OrangeIcon size='MD' icon='coupon' color={orange.extra} />
                    </div>
                    <div className='ml-2'>
                      <p className='fs-16 lh-19 text-grayscale--500 mt-1 fw-600'>Desconto</p>
                      <p className='mb-0 fs-14 lh-17 text-grayscale-400'>{item.discount.description}</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-start'>
                    <div>
                      <OrangeIcon size='MD' icon='receipt' color={orange.extra} />
                    </div>
                    <div className='ml-2'>
                      <p className='fs-16 lh-19 text-grayscale--500 mt-1 fw-600'>Requisitos</p>
                      <p className='mb-0 fs-14 lh-17 text-grayscale-400'>{item.requests.description}</p>
                    </div>
                  </div>
                </CardContainer>
              ))}
            </DefaultCarousel>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mt-3 text-center fw-400'>¹As taxas podem variar de acordo com a análise de crédito, que é feita individualmente.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Comparativo
