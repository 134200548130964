import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { grayscale } from 'src/styles/colors'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IBreadcrumbProps {
  sectionName: string;
  pageLink: string;
  textLink: string;
  pageName: string;
}

const Breadcrumb = ({ sectionName, pageLink, pageName, textLink }: IBreadcrumbProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <div className='d-flex align-items-center'>
      <a
        href={pageLink}
        className='mr-2 fs-14 lh-17 text-orange--extra'
        onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_1',
            section_name: sectionName,
            element_action: 'click button',
            element_name: textLink,
            redirect_url: pageLink,
          })
        }}
      >{textLink}
      </a>
      <OrangeIcon icon='arrow-right' size='SM' color={grayscale[500]} />
      <span className='ml-2 fs-14 lh-17 text-grayscale--500'>{pageName}</span>
    </div>
  )
}

export default Breadcrumb
