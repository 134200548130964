import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      oQueETaxaBonificada: imageSharp(fluid: { originalName: { regex: "/o-que-e-taxa-bonificada-desktop/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      taxaBonificacaoSticker: imageSharp(fluid: { originalName: { regex: "/taxa-bonificacao/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      taxaSemBonificacaoSticker: imageSharp(fluid: { originalName: { regex: "/taxa-sem-bonificacao/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      comoManterATaxa: imageSharp(fluid: { originalName: { regex: "/como-manter-a-taxa/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroBackgroundMobile: imageSharp(fluid: { originalName: { regex: "/hero-background-mobile/" } }) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
