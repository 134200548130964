import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

import BgMobile from '../../assets/images/hero-background-mobile.png'
import BgDesktop from '../../assets/images/hero-background-desktop.png'

export const HeroContainer = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 754px;
  background-image: url(${BgMobile});
  background-repeat: no-repeat;
  background-position: 8px 60px;
  background-size: contain;

  .breadcrumb {
    padding: 0 14px;
    padding-top: 25px;

    @media ${device.tablet} {
      padding: 0 24px;
      padding-top: 10px;
    }
  }

  .textgreen {
    color: #00AA4F;
  }

  a {
    color: ${orange.extra} !important;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
  }

  span {
    color: #000 !important;
  }
  @media ${device.tablet} {
    justify-content: center;
    align-items: center;
    height: 457px;
    background-image: url(${BgDesktop});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 370px;
  }

  @media ${device.desktopLG} {
    align-items: center;
    height: 502px;
    background-image: url(${BgDesktop});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 502px;
  }


  @media ${device.desktopXL} {
    align-items: center;
    height: 662px;
    background-image: url(${BgDesktop});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 580px;
  }
`
