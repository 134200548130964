import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const JurosReduzidos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://imobiliario.inter.co/financiamento-imobiliario#/informacoes-imovel?utm_source=LP%20Taxa%20Bonificada&utm_medium=crm&utm_campaign=Site%20Institucional&_ga=2.80581079.1758744645.1666289972-1777536335.1663950609'

  return (
    <div className='bg-orange--extra d-flex align-items-center justify-content-center' style={{ height: 312 }}>
      <div className='container py-5'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-4'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-white'>
              Taxa bonificada: juros reduzidos e parcelas que cabem no seu bolso!
            </h2>
          </div>
          <div className='col-12 col-md-6'>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--100 mb-3'>
              Pensando em financiar no Inter? Simule agora e aproveite as melhores condições para seu novo imóvel!
            </p>
            <a
              href={url}
              className='btn btn-white text-orange--extra fw-bold btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_6',
                  section_name: 'Taxa bonificada: juros reduzidos e parcelas que cabem no seu bolso!',
                  element_action: 'click button',
                  element_name: 'Simular meu financiamento',
                  redirect_url: url,
                 })
              }}
            >Simular meu financiamento
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JurosReduzidos
