import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const CardContainer = styled.div`
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #DEDFE4;
  border-radius: 16px;
  z-index: 0;
`

export const Circle = styled.div`
  height: 47px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${orange.extra};
  border: 2px solid white;
  border-radius: 24px;
  color: white;
  font-weight: bold;
  margin: -10px 0;
  z-index: 10;
`
