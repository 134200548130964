import React from 'react'
import { CardContainer, Circle } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'

const cardData = [
  {
    icon: 'investments',
    title: 'Investimentos',
    description: 'Cliente com saldo em qualquer modalidade de investimento durante o período de revisão da elegibilidade.',
  },
  {
    icon: 'deposit-by-check',
    title: 'Portabilidade de Salário',
    description: 'Cliente com portabilidade de salário ativo durante o período de revisão da elegibilidade.',
  },
  {
    icon: 'card',
    title: 'Cartão de Crédito',
    description: 'Cliente com compra e/ou limite utilizado mensalmente no cartão de crédito durante o período de revisão da elegibilidade.',
  },
]

const QuaisRequisitos = () => {
  return (
    <div className='py-5'>
      <div className='container py-md-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500 mb-4'>Quais os requisitos para conseguir a Taxa Bonificada?</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400'>A taxa bonificada é aplicada apenas quando <strong>pré-estabelecida nos contratos de crédito imobiliário.</strong><br /><br />Para continuar aproveitando os benefícios da taxa bonificada, o cliente deverá estar adimplente, realizar o pagamento das parcelas através do débito em conta e ser usuário de pelo menos <strong>um dos produtos</strong> ao lado:</p>
          </div>
          <div className='col-12 col-md-6 col-lg-6'>
            <div className='d-flex flex-column align-items-center'>
              <CardContainer>
                <div className='d-flex align-items-center mb-4'>
                  <div>
                    <OrangeIcon icon={cardData[0].icon} size='LD' color={orange.extra} />
                  </div>
                  <p className='fs-16 lh-19 text-grayscale--500 fw-700 ml-2 mb-0'>{cardData[0].title}</p>
                </div>
                <p className='fs-14 lh-17 text-grayscale--400 fw-400'>{cardData[0].description}</p>
              </CardContainer>
              <Circle>OU</Circle>
              <CardContainer>
                <div className='d-flex align-items-center mb-4'>
                  <div>
                    <OrangeIcon icon={cardData[1].icon} size='LG' color={orange.extra} />
                  </div>
                  <p className='fs-16 lh-19 text-grayscale--500 fw-700 ml-2 mb-0'>{cardData[1].title}</p>
                </div>
                <p className='fs-14 lh-17 text-grayscale--400 fw-400'>{cardData[1].description}</p>
              </CardContainer>
              <Circle>OU</Circle>
              <CardContainer>
                <div className='d-flex align-items-center mb-4'>
                  <div>
                    <OrangeIcon icon={cardData[2].icon} size='LG' color={orange.extra} />
                  </div>
                  <p className='fs-16 lh-19 text-grayscale--500 fw-700 ml-2 mb-0'>{cardData[2].title}</p>
                </div>
                <p className='fs-14 lh-17 text-grayscale--400 fw-400'>{cardData[2].description}</p>
              </CardContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuaisRequisitos
