export const contentData = [
  {
    title: 'Taxa bonificada',
    subtitle: 'A partir de ',
    tax: 'A partir de 11,49 a.a. + TR¹',
    image: 'taxaBonificacaoSticker',
    discount: {
      description: 'Taxa com desconto de 0,3 p.p. na taxa anual sem bonificação, pré estabelecida no contrato de crédito imobiliário.',
    },
    requests: {
      description: 'Estar adimplente, pagar as parcelas através do débito automático e ser usuário de pelo menos um dos produtos: investimentos, portabilidade de salário ou uso recorrente do cartão de crédito Inter.',
    },
  },
  {
    title: 'Taxa sem bonificação',
    subtitle: 'A partir de ',
    tax: 'A partir de 11,80% a.a. + TR¹',
    image: 'taxaSemBonificacaoSticker',
    discount: {
      description: 'Não há desconto na taxa de juros.',
    },
    requests: {
      description: 'Apenas aqueles previstos nas condições contratuais.',
    },
  },
]
